<template>
  <button class="kiss-me" @click="onClick"><span>Kiss Me!</span></button>
</template>

<script>
import { useTable } from '@/composables/table';
import { useGeolocation } from '@/composables/geolocation';
// import { locations } from '../../data/localions';

const useOnClick = (emit) => async () => {
  const { check, getCurrentPosition } = useGeolocation();
  const { addItem } = useTable();
  if (check()) {
    const position = await getCurrentPosition();
    const result = await addItem(position.coords.latitude,
      position.coords.longitude,
      position.timestamp,
      'kiss');
    if (result) {
      // eslint-disable-next-line no-alert
      alert('Kissed!');
      emit('click');
    } else {
      // eslint-disable-next-line no-alert
      alert('Error while kissing!');
    }
  }
};

// const addAllLocations = async () => {
//   const { addItem } = useTable();
//   const locs = locations;
//   // eslint-disable-next-line no-plusplus
//   for (let i = 0; i < locs.length; i++) {
//     const loc = locs[i];
//     // eslint-disable-next-line no-await-in-loop
//     await addItem(loc.lat,
//       loc.lon,
//       loc.timestamp || 0,
//       loc.category || 'kiss');
//   }
//   // eslint-disable-next-line no-alert
//   alert('done');
// };

export default {
  props: {
    click: Function,
  },
  setup(props, { emit }) {
    const onClick = useOnClick(emit);
    return { onClick };
  },
};
</script>

<style scoped>

button{
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  -webkit-user-select: none;
  -moz-user-select: none;
}

button.kiss-me{
  height: 40px;
  padding: 0 15px;
  border-radius: 20px;
  color: var(--text-pink);
  font-weight: bolder;
  font-size: 14px;
  cursor: pointer;
  background: linear-gradient(135deg, var(--pink) 0%, var(--orange) 100%);
  background-color: var(--pink);
  box-shadow: 3px 3px 4px rgba(0,0,0,0.25),-2px -2px 3px rgba(255,255,255,0.3);

  transition: 0.2s ease-out;

  font-family: 'Dancing Script';
}

button.kiss-me span{
  background: var(--text-pink);
  text-shadow: 1px 1px 2px rgba(255,255,255,0.3);
  color: transparent;
  background-clip: text;
}

button.kiss-me:hover{
    box-shadow: 4px 4px 6px rgba(0,0,0,0.25), -3px -3px 5px rgba(255,255,255,0.3);
}

button.kiss-me:active{
    box-shadow: 3px 3px 4px rgba(0,0,0,0.25) inset, -2px -2px 3px rgba(255,255,255,0.3) inset;
}

button.kiss-me:focus{
  outline: none;
}

</style>
