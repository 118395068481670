import firstKiss from '../assets/first_kiss.png';
import almostKiss from '../assets/almost_kiss.png';
import antrag from '../assets/antrag2.png';
import sex from '../assets/sex.png';
import kiss1 from '../assets/kiss1.png';
import kiss2 from '../assets/kiss2.png';
import kiss3 from '../assets/kiss3.png';
import kiss4 from '../assets/kiss4.png';
import kiss5 from '../assets/kiss5.png';
import kiss6 from '../assets/kiss6.png';
import kiss7 from '../assets/kiss7.png';
import kiss8 from '../assets/kiss8.png';
import kiss9 from '../assets/kiss9.png';
import kiss10 from '../assets/kiss10.png';
import kiss11 from '../assets/kiss11.png';
import kiss12 from '../assets/kiss12.png';
import kiss13 from '../assets/kiss13.png';
import kiss14 from '../assets/kiss14.png';
import kiss15 from '../assets/kiss15.png';

const kisses = [
  kiss1,
  kiss2,
  kiss3,
  kiss4,
  kiss5,
  kiss6,
  kiss7,
  kiss8,
  kiss9,
  kiss10,
  kiss11,
  kiss12,
  kiss13,
  kiss14,
  kiss15,
];

const pickRandomKiss = () => {
  // eslint-disable-next-line no-bitwise
  const rnd = Math.random() * kisses.length | 0;
  return kisses[rnd];
};

export const getImage = (type) => {
  switch (type) {
    case 'first kiss': return firstKiss;
    case 'almost kiss': return almostKiss;
    case 'antrag': return antrag;
    case 'sex': return sex;
    case 'kiss': return pickRandomKiss();
    default: return kiss1;
  }
};
