import AWS from 'aws-sdk';

const tableName = 'loveplaces';

const addNewItem = (lat, lon, timestamp, type) => new Promise((resolve, reject) => {
  const docClient = new AWS.DynamoDB.DocumentClient();
  const params = {
    TableName: tableName,
    Item: {
      id: `${lat}_${lon}_${timestamp}`,
      lat,
      lon,
      timestamp,
      type,
    },
  };
  docClient.put(params, (err, data) => {
    if (err) {
      reject(err);
    } else {
      resolve(data);
    }
  });
});

const getAll = () => new Promise((resolve, reject) => {
  const docClient = new AWS.DynamoDB.DocumentClient();
  const params = {
    TableName: tableName,
  };
  docClient.scan(params, (err, data) => {
    if (err) {
      reject(err);
    } else {
      resolve(data);
    }
  });
});

export const useTable = () => ({
  addItem: (lat, lon, timestamp, type) => addNewItem(lat, lon, timestamp, type),
  getAllItems: () => getAll(),
});
