import { Loader } from '@googlemaps/js-api-loader';
import { adventureStyle } from './mapStyles';
import { getImage } from './imageProvider';

let loader;
let map;
const markers = [];
let Popup;
const popups = [];
// const content = document.createElement('div');

const initialize = async () => {
  loader = new Loader({
    apiKey: 'AIzaSyD4cGo4flNC1b4v8bvBYke0ZA8V7eMjTs0',
  });
  await loader.load();

  // eslint-disable-next-line global-require
  Popup = require('./popup').Popup;
};

const showMap = (el, lat, lng) => {
  const mapOptions = {
    center: {
      lat,
      lng,
    },
    zoom: 15,
    fullscreenControl: false,
    disableDefaultUI: true,
    styles: adventureStyle,
  };
  // eslint-disable-next-line no-undef
  map = new google.maps.Map(el, mapOptions);
  map.addListener('click', () => {
    popups.forEach((p) => p.setMap(null));
  });
  return map;
};

const getTextForType = (type) => {
  switch (type) {
    case 'sex': return 'Ein besonderer Kuss';
    case 'first kiss': return 'Der erste Kuss';
    case 'almost kiss': return 'Kuss verpasst';
    case 'antrag': return 'Die Frage';
    default: return 'Kuss';
  }
};

const leftPad = (n) => (n > 9 ? `${n}` : `0${n}`);

const genetareDateTime = (timestamp) => {
  const date = new Date(timestamp);
  return `am ${leftPad(date.getDate())}.${leftPad(date.getMonth() + 1)}.${date.getFullYear()} um ${leftPad(date.getHours())}:${leftPad(date.getMinutes())} Uhr`;
};

const addInfoBoxToMarker = (marker, type, timestamp) => {
  marker.addListener('click', () => {
    popups.forEach((p) => p.setMap(null));

    // const { children } = content;
    // for (let i = children.length - 1; i > 0; i--) {
    //   content.removeChild(children[i]);
    // }
    const content = document.createElement('div');
    content.className = 'info-box';

    const kathegoryDiv = document.createElement('div');
    kathegoryDiv.className = 'type';
    kathegoryDiv.innerText = getTextForType(type);
    content.appendChild(kathegoryDiv);

    if (timestamp !== 0) {
      const timeDiv = document.createElement('div');
      timeDiv.className = 'time';
      timeDiv.innerText = genetareDateTime(timestamp);
      content.appendChild(timeDiv);
    }

    const popup = new Popup(
      marker,
      content,
    );
    popup.setMap(map);
    popups.push(popup);
  });
};

const getZIndex = (type) => {
  if (type === 'kiss') return 1;
  return 2;
};

const addMarker = (position, type, timestamp) => {
  const icon = getImage(type);
  const zIndex = getZIndex(type);
  // eslint-disable-next-line no-undef
  const marker = new google.maps.Marker({
    position,
    map,
    icon,
    optimized: true,
    zIndex,
  });
  markers.push(marker);
  addInfoBoxToMarker(marker, type, timestamp);
};

const setMarkerOverlayId = () => {
  // eslint-disable-next-line no-undef
  const myoverlay = new google.maps.OverlayView();
  myoverlay.draw = () => {
    myoverlay.getPanes().markerLayer.id = 'markerLayer';
  };
  myoverlay.setMap(map);
};

export const useMaps = () => ({
  initialize: () => initialize(),
  showMap: (el, lat, lng) => showMap(el, lat, lng),
  addMarker: (position, type, timestamp) => addMarker(position, type, timestamp),
  setMarkerOverlayId: () => setMarkerOverlayId(),
});
