const check = () => {
  if ('geolocation' in navigator) {
    return true;
  }
  return false;
};

const getCurrentPosition = () => new Promise((resolve, reject) => {
  navigator.geolocation.getCurrentPosition(resolve, reject);
});

export function useGeolocation() {
  return ({
    check,
    getCurrentPosition,
  });
}
