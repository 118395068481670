<template>
  <div class="map-view">
    <!-- <Headersub /> -->
    <div class="kiss-map" id="kiss-map">
    <!-- Map -->
    </div>
  </div>
  <AddNewButton class="new-button"/>
</template>

<script>
/* eslint-disable no-undef */
import { onMounted } from 'vue';
import { useGeolocation } from '@/composables/geolocation';
import AddNewButton from './AddNewButton.vue';
import { useMaps } from '../composables/map';
import { useTable } from '../composables/table';

export default {
  components: {
    // Headersub,
    AddNewButton,
  },
  setup() {
    const {
      initialize, showMap, addMarker,
    } = useMaps();
    const { getAllItems } = useTable();
    const { check, getCurrentPosition } = useGeolocation();
    onMounted(async () => {
      await initialize();
      let position;
      if (check()) {
        try {
          position = await getCurrentPosition();
        } catch (e) {
          // ignore
        }
      }
      if (position) {
        showMap(document.getElementById('kiss-map'),
          position.coords.latitude,
          position.coords.longitude);
      } else {
        showMap(document.getElementById('kiss-map'), 50.351462, 7.597255);
      }

      const { Items } = await getAllItems();
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < Items.length; i++) {
        const item = Items[i];
        addMarker({ lat: item.lat, lng: item.lon }, item.type, item.timestamp);
      }
    });
  },
};
</script>

<style>
.map-view{
  /* height: calc(100% - 100px); */
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

.info-box{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color:var(--text-pink);
}

.info-box .type{
  width: 110%;
  font-size: 18px;
  font-weight: bold;
  background: linear-gradient(135deg, var(--green) 0%, var(--blue) 100%);
  text-shadow: 1px 1px 1px rgba(0,0,0,0.05), -1px -1px 1px rgba(255,255,255,0.03);
  box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.25),-3px -3px 5px 0px rgba(255,255,255,0.3);
  border-radius: 10px;
  padding: 1px 0;

  font-family: 'Dancing Script';

}

.info-box .time{
  margin-top: 5px;
  font-size: 12px;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.25), -1px -1px 2px rgba(255,255,255,0.3);
  font-family: 'Anonymous Pro';
}

.kiss-map{
  height: 100%;
}
.new-button{
  position: absolute;
  bottom: 30px;
  right: 30px;
}

#markerLayer img {
  width: 70% !important;
  height: 70% !important;
  border-radius: 10px;
  box-shadow: 3px 3px 5px rgba(0,0,0,0.25),-2px -2px 3px rgba(255,255,255,0.3);
}

.popup-bubble {
  /* Position the bubble centred-above its parent. */
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-radius: 15px;
  overflow-y: visible;
  font-family: sans-serif;
  background: linear-gradient(135deg, var(--green) 0%, var(--blue) 100%);
  box-shadow: 3px 3px 5px rgba(0,0,0,0.25),-2px -2px 3px rgba(255,255,255,0.3);

  transition: height 0.2s ease-out, width 0.2s ease-out;
  transform: translate(-50%, -100%);
}

.show .popup-bubble{
  height: 60px;
  width: 200px;
}

/* The parent of the bubble. A zero-height div at the top of the tip. */
.popup-bubble-anchor {
  /* Position the div a fixed distance above the tip. */
  position: absolute;
  width: 100%;
  bottom: 8px;
  left: 0;
}

/* This element draws the tip. */
.popup-bubble-anchor::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  /* Center the tip horizontally. */
  transform: translate(-50%, -430%);
  /* The tip is a https://css-tricks.com/snippets/css/css-triangle/ */
  width: 0;
  height: 0;
  /* The tip is 8px high, and 12px wide. */
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 8px solid white;
}

/* JavaScript will position this div at the bottom of the popup tip. */
.popup-container {
  position: absolute;
  width: 0;
  height: 0;
  cursor: auto;
}
</style>
